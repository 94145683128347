import React from 'react';
import Header from 'components/Header';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Posts from 'components/Posts';

const BlogPage: React.FC = () => {
  return (
    <Layout >
      <SEO title="Blog" />
      <Posts />
    </Layout>
  );
};

export default BlogPage;
